import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme, GridSize } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { Link, navigate } from 'gatsby';
import { resellerService } from '@myneva-portals/services/src/services';
import { Formik, Form, FastField } from 'formik';
import { getCountryCode } from '@myneva-portals/utils/src/utils/get-country-util';
import { changeEmptyStringsToNull } from '@myneva-portals/utils/src/utils/change-empty-string-to-null-util';
import {
  newResellerInitData,
  newResellerInitErrors,
} from '@myneva-portals/models/src/models/new-reseller-init-data';
import { useTranslation } from 'react-i18next';
import {
  EBillingAddressType,
  EAccountAdminContactType,
} from '@myneva-portals/models/src/components/sections/static-data/resellers-enums';
import { useDropzone } from 'react-dropzone';
import { AddResellerNote } from '../../components/reseller/add-reseller-note';
import {
  formErrorToast,
  defaultErrorToast,
  redirectErrorToast,
} from '../../components/sections/static-data/default-error-toast';
import { UploadFile } from '@myneva-portals/projects/src/components/';
import { getThemeMode } from 'front-components/src/tools/theme-extensions';
import { H3, Caption2, Subheading1 } from 'front-components/src/themes';
import {
  PrimaryButton,
  SecondaryButton,
  FormLabel,
  FormCheckbox,
  FormInputValidateError,
  ErrorBar,
  showToast,
  Card,
} from 'front-components/src/components';
import { ECardType } from 'front-components/src/constants';
import { ContentBar } from '@myneva-portals/projects/src/components';
import ResellerDetails from '@myneva-portals/models/src/models/reseller/reseller-details';
import { formValidation } from '@myneva-portals/utils/src/utils/form-validation-regex';
import { EFormValidation } from 'front-components/src/constants';
import { EFormType } from '@myneva-portals/models/src/components/sections/static-data/form-type-enum';
import { InputWrapperProps } from '@myneva-portals/models/src/models/forms/input-wrapper/input-wrapper.props';
import { NoteWrapper } from '@myneva-portals/projects/src/components/note-wrapper/note-wrapper';
import { PhoneFieldWrapper } from '@myneva-portals/projects/src/components/phone-field-wrapper/phone-field-wrapper';
import { FieldWrapper } from '@myneva-portals/projects/src/components/field-wrapper/field-wrapper';
import { AutocompleteCountryWrapper } from '@myneva-portals/projects/src/components/autocomplete-country-wrapper/autocomplete-country-wrapper';

export const AddResellerContent: React.FC = () => {
  const useStyles = makeStyles(
    (theme: Theme) =>
      createStyles({
        ResellerDetailsContainer: {
          boxSizing: 'border-box',
          padding: '48px 56px 48px 56px',
          backgroundColor: getThemeMode(theme).general.background,
        },
        checkBox: {
          color: getThemeMode(theme).text.text01,
        },
        H3class: {
          ...H3,
          color: getThemeMode(theme).text.text01,
        },
        Caption2class: {
          ...Caption2,
          color: getThemeMode(theme).text.text02,
        },
        Subheading1: {
          ...Subheading1,
          marginBottom: theme.spacing(1),
          color: getThemeMode(theme).text.text01,
        },
        SubheadingWrapper: {
          marginBottom: theme.spacing(6),
          marginTop: theme.spacing(6),
        },
        SubheadingWrapperAdmin: {
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(6),
        },
        Subheading1Label: {
          ...Subheading1,
          paddingBottom: theme.spacing(3),
          marginTop: theme.spacing(3),
          color: getThemeMode(theme).text.text01,
        },
        GridTopSpacing32: {
          marginTop: theme.spacing(4),
        },
        NoteWrapper: {
          marginTop: theme.spacing(1),
        },
        checkBoxWrapperBilling: {
          marginTop: theme.spacing(4),
        },
        checkBoxWrapperAdmin: {
          marginBottom: '12px',
        },
        BillingAddressWrapper: {
          marginTop: theme.spacing(3),
        },
        gridItemSpacing: {
          marginTop: theme.spacing(4),
        },
        link: {
          textDecoration: 'none',
        },
        infoIcon: {
          color: getThemeMode(theme).icon.icon02,
          width: '18px',
          height: '18px',
          marginTop: '4px',
        },
        errorCoitaniner: {
          width: '100%',
          height: '20px',
        },
        errorCoitaninerZip: {
          width: '100%',
          paddingBottom: '5%',
          '@media (max-width: 1138px)': {
            paddingBottom: '12%',
          },
        },
      }),
    { name: 'add-reseller' }
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const toastConfig = defaultErrorToast();
  const formToastConfig = formErrorToast();
  const redirectToastConfig = redirectErrorToast();

  const { breadcrumbs } = useSelector(navigationSelector);
  const formikRef = useRef();

  let uploadedFile;
  const noteLenghtError = [null, null, null];
  let checkWholeForm = false;
  let localErrors = [];

  const checkboxesDataHandler = async (values) => {
    if (
      values.reseller.Address.billingAddressType === EBillingAddressType.ADDRESS
    ) {
      values.reseller.Address.BillingAddress.country =
        values.reseller.Address.country;
      values.reseller.Address.BillingAddress.address1 =
        values.reseller.Address.address1;
      values.reseller.Address.BillingAddress.address2 =
        values.reseller.Address.address2;
      values.reseller.Address.BillingAddress.postcode =
        values.reseller.Address.postcode;
      values.reseller.Address.BillingAddress.city =
        values.reseller.Address.city;
      values.reseller.Address.BillingAddress.state =
        values.reseller.Address.state;
    } else {
      values.reseller.Address.BillingAddress.country = await getCountryCode(
        values.reseller.Address.BillingAddress.country
      );
    }
    if (
      values.reseller.Contacts.accountAdmin.accountAdminContactType ===
      EAccountAdminContactType.SAME_AS_TECH
    ) {
      values.reseller.Contacts.accountAdmin.email =
        values.reseller.Contacts.techSupport.email;
      values.reseller.Contacts.accountAdmin.phone =
        values.reseller.Contacts.techSupport.phone;
      values.reseller.Contacts.accountAdmin.faxNumber =
        values.reseller.Contacts.techSupport.faxNumber;
      values.reseller.Contacts.accountAdmin.firstName =
        values.reseller.Contacts.techSupport.firstName;
      values.reseller.Contacts.accountAdmin.surname =
        values.reseller.Contacts.techSupport.surname;
      values.reseller.Contacts.accountAdmin.note =
        values.reseller.Contacts.techSupport.note;
    }
    return values;
  };

  const addReseller = async (valuesComplete: ResellerDetails, setError) => {
    try {
      await resellerService.addReseller(valuesComplete);
      navigate('/resellers');
    } catch (e) {
      if (e.response.status == 400) {
        e?.response.data.errors.map((item) => {
          const fieldName =
            item.fieldName && item.fieldName.replaceAll('_', '');
          localErrors.push(EFormValidation.DUPLICATE + fieldName);
          switch (item.fieldName) {
            case 'invoicing_nr':
              setError(
                'reseller.General.invocingNumber',
                EFormValidation.DUPLICATE + item.fieldName
              );
              break;
            case 'name':
              setError(
                'reseller.General.companyName',
                EFormValidation.DUPLICATE + item.fieldName
              );
              break;
            case 'internal_accounting_nr':
              setError(
                'reseller.General.internalAccountingNumber',
                EFormValidation.DUPLICATE + item.fieldName
              );
              break;
            default:
              setError(
                'reseller.Contacts.accountAdmin.' + fieldName,
                EFormValidation.DUPLICATE + fieldName
              );
          }
        });
        showToast(formToastConfig, localErrors);
        localErrors = [];
      } else if (e.response.status == 403) {
        showToast(redirectToastConfig);
      } else {
        showToast(toastConfig);
      }
    }
  };

  const submit = async (values: ResellerDetails, setError, setTouched) => {
    if (uploadedFile) {
      values.avatar = uploadedFile;
    }
    values.reseller.Address.country = await getCountryCode(
      values.reseller.Address.country
    );
    if (
      values.reseller.Contacts.accountAdmin.email &&
      values.reseller.Contacts.accountAdmin.phone?.length <= 3
    ) {
      values.reseller.Contacts.accountAdmin.phone = null;
    }
    let valuesComplete = JSON.parse(JSON.stringify(values));
    valuesComplete = await checkboxesDataHandler(valuesComplete);
    const valuesEmptyStringsHandled = await changeEmptyStringsToNull(
      valuesComplete
    );
    addReseller(valuesEmptyStringsHandled, setError);
    setTouched({}, false);
  };

  const validateField = (id, value, touched, required, initialError, type?) => {
    let error = '';
    const inputName = id.split('.').slice(-1);
    const inputPath = id.split('.').slice(-2).join('.');
    if (touched || checkWholeForm) {
      if (!type || type == EFormType.GENERAL) {
        error = formValidation.checkLength(value);
      } else {
        if (type == EFormType.EMAIL && (required || value)) {
          error = formValidation.checkEmail(value);
        }
        if (type == EFormType.PHONE && (required || value?.length > 3)) {
          error = formValidation.checkPhone(value);
        }
        if (value && type == EFormType.FAX) {
          if (value.length > 3) {
            error = formValidation.checkFax(value);
          }
        }
        if (type == EFormType.ZIP) {
          error = formValidation.checkZip(value);
        }
      }
      if (!value && required) {
        error = EFormValidation.REQUIRED;
      }
      if (checkWholeForm) {
        error && localErrors.push(error + inputPath);
      }
      return error ? (error = error + inputName) : (error = '');
    } else {
      return initialError ? initialError : '';
    }
  };

  const validateNotes = (values) => {
    if (values.reseller.Contacts.techSupport.note) {
      const error = formValidation.checkLength(
        values.reseller.Contacts.techSupport.note,
        500
      );
      noteLenghtError[0] = error ? error + 'techSupport.note' : error;
    } else {
      noteLenghtError[0] = null;
    }
    if (values.reseller.Contacts.accountAdmin.note) {
      const error = formValidation.checkLength(
        values.reseller.Contacts.accountAdmin.note,
        500
      );
      noteLenghtError[1] = error ? error + 'accountAdmin.note' : error;
    } else {
      noteLenghtError[1] = null;
    }
    if (values.reseller.Contacts.billingContact.note) {
      const error = formValidation.checkLength(
        values.reseller.Contacts.billingContact.note,
        500
      );
      noteLenghtError[2] = error ? error + 'billingContact.note' : error;
    } else {
      noteLenghtError[2] = null;
    }
    if (!noteLenghtError.every((item) => item == null)) {
      return 'ERROR';
    }
  };

  const handleErrorModal = (errors) => {
    if (errors) {
      showToast(formToastConfig, errors);
      localErrors = [];
    }
  };

  const getFileObcject = (file) => {
    uploadedFile = file;
  };

  const InputWrapper = (
    props: InputWrapperProps<GridSize, React.ReactNode>
  ) => {
    return (
      <Grid
        item
        md={props.mdSize}
        xs={props.xsSize}
        className={props.className}
      >
        <FormLabel text={props.text} isRequired={props.required} />
        <FastField
          id={props.id}
          name={props.id}
          placeholder={props.placeholder}
          error={props.error}
          required={props.required}
          component={props.component}
          validate={(value) =>
            validateField(
              props.id,
              value,
              props.touched,
              props.required,
              props.error,
              props.type
            )
          }
          cyName={props.cyName}
          defaultValue={props.defaultValue}
          searchPlaceholder={props.searchPlaceholder}
          defaultCountry={props.defaultCountry}
          matchPortalForms={props.matchPortalForms}
        />
        {props.error && (
          <FormInputValidateError
            errorMessage={t(props.error)}
            type={props.type}
          />
        )}
      </Grid>
    );
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={newResellerInitData}
      initialErrors={newResellerInitErrors}
      validate={validateNotes}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={(values, actions) => {
        submit(values, actions.setFieldError, actions.setTouched);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldError,
        submitForm,
        errors,
        touched,
      }) => (
        <>
          <ContentBar
            translation={t}
            breadcrumbsItems={breadcrumbsUtils.getBreadcrumbs(breadcrumbs)}
            actionPanelContent={
              <>
                <div>
                  <Link className={classes.link} to={'/resellers'}>
                    <SecondaryButton
                      size={'small'}
                      label={t('EXIT')}
                      cyName="resellers-add-close-button"
                    />
                  </Link>
                </div>
                <div>
                  <PrimaryButton
                    label={t('SAVE')}
                    size={'small'}
                    disabled={false}
                    onClick={async () => {
                      checkWholeForm = true;
                      submitForm().finally(() => {
                        const entireErrors =
                          localErrors.concat(noteLenghtError);
                        return (
                          entireErrors.some((val) => val) &&
                          handleErrorModal(entireErrors)
                        );
                      });
                      checkWholeForm = false;
                    }}
                    cyName="resellers-add-save-button"
                  />
                </div>
              </>
            }
          />

          <Form>
            <div className={classes.ResellerDetailsContainer}>
              <Card cardType={ECardType.FORM}>
                <div>
                  {' '}
                  <span className={classes.Caption2class}>
                    {t('STEP_1')}
                  </span>{' '}
                </div>
                <div>
                  {' '}
                  <span className={classes.H3class}>{t('GENERAL')}</span>{' '}
                </div>

                <UploadFile
                  updateFile={getFileObcject}
                  cyName="resellers-upload"
                  useDropzone={useDropzone}
                  translation={t}
                />

                <Grid container alignItems="center" spacing={2}>
                  <InputWrapper
                    mdSize={4}
                    xsSize={12}
                    text={t('COMPANY_NAME')}
                    id="reseller.General.companyName"
                    name="COMPANY_NAME"
                    placeholder={t('COMPANY_NAME_PLACEHOLDER')}
                    required={true}
                    error={errors.reseller?.General?.companyName}
                    component={FieldWrapper}
                    cyName="resellers-add-companyname-input"
                    touched={touched.reseller?.General?.companyName}
                    type={EFormType.GENERAL}
                  />

                  <InputWrapper
                    mdSize={4}
                    xsSize={12}
                    text={t('INVOICING_NUMBER')}
                    id="reseller.General.invocingNumber"
                    name="INVOICING_NUMBER"
                    placeholder={t('INVOICING_NUMBER_PLACEHOLDER')}
                    error={errors.reseller?.General?.invocingNumber}
                    component={FieldWrapper}
                    cyName="resellers-add-invoicingnumber-input"
                    touched={touched.reseller?.General?.invocingNumber}
                    type={EFormType.GENERAL}
                  />

                  <InputWrapper
                    mdSize={4}
                    xsSize={12}
                    text={t('INTERNAL_ACCOUNTING_NUMBER2')}
                    id="reseller.General.internalAccountingNumber"
                    name="INTERNAL_ACCOUNTING_NUMBER2"
                    placeholder={t('INTERNAL_ACCOUNTING_NUMBER2_PLACEHOLDER')}
                    error={errors.reseller?.General?.internalAccountingNumber}
                    component={FieldWrapper}
                    cyName="resellers-add-accountingnumber-input"
                    touched={
                      touched.reseller?.General?.internalAccountingNumber
                    }
                    type={EFormType.GENERAL}
                  />
                </Grid>
                {(errors.reseller?.General?.companyName ||
                  errors.reseller?.General?.internalAccountingNumber ||
                  errors.reseller?.General?.invocingNumber) && (
                  <div className={classes.errorCoitaniner} />
                )}
              </Card>

              <Card cardType={ECardType.FORM}>
                {' '}
                <span className={classes.Caption2class}>
                  {t('STEP_2')}
                </span>{' '}
                <div>
                  {' '}
                  <span className={classes.H3class}>{t('ADDRESS')}</span>{' '}
                </div>
                <div className={classes.Subheading1Label}>{t('COMPANY')}</div>
                <InputWrapper
                  mdSize={4}
                  xsSize={12}
                  text={t('COUNTRY')}
                  id="reseller.Address.country"
                  name="Country"
                  placeholder={t('SELECT_PLACEHOLDER')}
                  error={errors.reseller?.Address?.country}
                  required={true}
                  component={AutocompleteCountryWrapper}
                  cyName="resellers-add-company-country-input"
                  touched={touched.reseller?.Address?.country}
                />
                {errors.reseller?.Address?.country && (
                  <div className={classes.errorCoitaniner} />
                )}
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  className={classes.GridTopSpacing32}
                >
                  <InputWrapper
                    mdSize={6}
                    xsSize={12}
                    text={t('ADDRESS_1')}
                    id="reseller.Address.address1"
                    name="ADDRESS_1"
                    placeholder={t('ADDRESS_1_PLACEHOLDER')}
                    error={errors.reseller?.Address?.address1}
                    required={true}
                    component={FieldWrapper}
                    cyName="resellers-add-company-address1-input"
                    touched={touched.reseller?.Address?.address1}
                  />
                  <InputWrapper
                    mdSize={6}
                    xsSize={12}
                    text={t('ADDRESS_2')}
                    id="reseller.Address.address2"
                    name="ADDRESS_2"
                    placeholder={t('ADDRESS_2_PLACEHOLDER')}
                    error={errors.reseller?.Address?.address2}
                    required={true}
                    component={FieldWrapper}
                    cyName="resellers-add-company-address2-input"
                    touched={touched.reseller?.Address?.address2}
                  />
                </Grid>
                {(errors.reseller?.Address?.address1 ||
                  errors.reseller?.Address?.address2) && (
                  <div className={classes.errorCoitaniner} />
                )}
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  className={classes.GridTopSpacing32}
                >
                  <InputWrapper
                    mdSize={2}
                    xsSize={6}
                    text={t('ZIP')}
                    id="reseller.Address.postcode"
                    name="ZIP"
                    placeholder={t('ZIP_PLACEHOLDER')}
                    error={errors.reseller?.Address?.postcode}
                    required={true}
                    component={FieldWrapper}
                    type={EFormType.ZIP}
                    cyName="resellers-add-company-zip-input"
                    touched={touched.reseller?.Address?.postcode}
                  />
                  <InputWrapper
                    mdSize={5}
                    xsSize={12}
                    text={t('TOWN')}
                    id="reseller.Address.city"
                    name="TOWN"
                    placeholder={t('TOWN_PLACEHOLDER')}
                    error={errors.reseller?.Address?.city}
                    required={true}
                    component={FieldWrapper}
                    cyName="resellers-add-company-city-input"
                    touched={touched.reseller?.Address?.city}
                  />
                  <InputWrapper
                    mdSize={3}
                    xsSize={12}
                    text={t('COUNTY')}
                    id="reseller.Address.state"
                    name="COUNTY"
                    placeholder={t('COUNTY_PLACEHOLDER')}
                    error={errors.reseller?.Address?.state}
                    required={true}
                    component={FieldWrapper}
                    cyName="resellers-add-company-state-input"
                    touched={touched.reseller?.Address?.state}
                  />
                </Grid>
                {(errors.reseller?.Address?.state ||
                  errors.reseller?.Address?.city ||
                  errors.reseller?.Address?.postcode) && (
                  <div className={classes.errorCoitaninerZip} />
                )}
                <div className={classes.checkBoxWrapperBilling}>
                  <FormLabel text={t('BILLING_ADDRESS')} isRequired={true} />

                  <FormCheckbox
                    index={1}
                    id={EBillingAddressType.ADDRESS}
                    checked={
                      values.reseller.Address.billingAddressType ==
                      EBillingAddressType.ADDRESS
                    }
                    label={t('RESELER_CHECKBOX_1')}
                    onChange={() => {
                      setFieldValue(
                        'reseller.Address.billingAddressType',
                        EBillingAddressType.ADDRESS
                      );
                    }}
                    cyName="resellers-add-billing-same-radiobtn"
                  />

                  <FormCheckbox
                    index={2}
                    id={EBillingAddressType.NONE}
                    checked={
                      values.reseller.Address.billingAddressType !==
                      EBillingAddressType.NONE
                    }
                    label={t('RESELER_CHECKBOX_2')}
                    onChange={() => {
                      setFieldValue(
                        'reseller.Address.billingAddressType',
                        EBillingAddressType.NONE
                      );
                    }}
                    cyName="resellers-add-billing-dif-radiobtn"
                  />
                </div>
                {values.reseller.Address.billingAddressType !==
                EBillingAddressType.ADDRESS ? (
                  <div className={classes.BillingAddressWrapper}>
                    <InputWrapper
                      mdSize={4}
                      xsSize={12}
                      text={t('COUNTRY')}
                      id="reseller.Address.BillingAddress.country"
                      name="billingCountry"
                      placeholder={t('SELECT_PLACEHOLDER')}
                      error={errors.reseller?.Address?.BillingAddress?.country}
                      required={true}
                      component={AutocompleteCountryWrapper}
                      cyName="resellers-add-billing-country-input"
                      touched={
                        touched.reseller?.Address?.BillingAddress?.country
                      }
                    />
                    {errors.reseller?.Address?.BillingAddress?.country && (
                      <div className={classes.errorCoitaniner} />
                    )}
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      className={classes.GridTopSpacing32}
                    >
                      <InputWrapper
                        mdSize={6}
                        xsSize={12}
                        text={t('ADDRESS_1')}
                        id="reseller.Address.BillingAddress.address1"
                        name="ADDRESS_1"
                        required={true}
                        placeholder={t('ADDRESS_1_PLACEHOLDER')}
                        error={
                          errors.reseller?.Address?.BillingAddress?.address1
                        }
                        component={FieldWrapper}
                        cyName="resellers-add-billing-address1-input"
                        touched={
                          touched.reseller?.Address?.BillingAddress?.address1
                        }
                      />

                      <InputWrapper
                        mdSize={6}
                        xsSize={12}
                        text={t('ADDRESS_2')}
                        id="reseller.Address.BillingAddress.address2"
                        name="ADDRESS_2"
                        required={true}
                        placeholder={t('ADDRESS_2_PLACEHOLDER')}
                        error={
                          errors.reseller?.Address?.BillingAddress?.address2
                        }
                        component={FieldWrapper}
                        cyName="resellers-add-billing-address2-input"
                        touched={
                          touched.reseller?.Address?.BillingAddress?.address2
                        }
                      />
                    </Grid>

                    {(errors.reseller?.Address?.BillingAddress?.address2 ||
                      errors.reseller?.Address?.BillingAddress?.address1) && (
                      <div className={classes.errorCoitaniner} />
                    )}
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      className={classes.GridTopSpacing32}
                    >
                      <InputWrapper
                        mdSize={2}
                        xsSize={6}
                        text={t('ZIP')}
                        id="reseller.Address.BillingAddress.postcode"
                        name="ZIP"
                        placeholder={t('ZIP_PLACEHOLDER')}
                        error={
                          errors.reseller?.Address?.BillingAddress?.postcode
                        }
                        required={true}
                        type={EFormType.ZIP}
                        component={FieldWrapper}
                        cyName="resellers-add-billing-zip-input"
                        touched={
                          touched.reseller?.Address?.BillingAddress?.postcode
                        }
                      />

                      <InputWrapper
                        mdSize={5}
                        xsSize={12}
                        text={t('TOWN')}
                        id="reseller.Address.BillingAddress.city"
                        name="TOWN"
                        placeholder={t('TOWN_PLACEHOLDER')}
                        error={errors?.reseller?.Address?.BillingAddress?.city}
                        required={true}
                        component={FieldWrapper}
                        cyName="resellers-add-billing-city-input"
                        touched={
                          touched.reseller?.Address?.BillingAddress?.city
                        }
                      />
                      <InputWrapper
                        mdSize={3}
                        xsSize={12}
                        text={t('COUNTY')}
                        id="reseller.Address.BillingAddress.state"
                        name="COUNTY"
                        placeholder={t('COUNTY_PLACEHOLDER')}
                        error={errors.reseller?.Address?.BillingAddress?.state}
                        required={true}
                        component={FieldWrapper}
                        cyName="resellers-add-billing-state-input"
                        touched={
                          touched.reseller?.Address?.BillingAddress?.state
                        }
                      />
                    </Grid>

                    {(errors?.reseller?.Address?.BillingAddress?.state ||
                      errors?.reseller?.Address?.BillingAddress?.city ||
                      errors?.reseller?.Address?.BillingAddress?.postcode) && (
                      <div className={classes.errorCoitaninerZip} />
                    )}
                  </div>
                ) : null}
              </Card>

              <Card cardType={ECardType.FORM}>
                <div>
                  {' '}
                  <span className={classes.Caption2class}>{t('STEP_3')}</span>
                </div>
                <div>
                  {' '}
                  <span className={classes.H3class}>{t('CONTACTS')}</span>
                </div>
                <div className={classes.Subheading1Label}>
                  {t('TECH_SUPP_CONTACT')}{' '}
                  <InfoOutlined className={classes.infoIcon} />
                </div>

                <Grid container alignItems="center" spacing={2}>
                  <InputWrapper
                    mdSize={6}
                    xsSize={6}
                    text={t('EMAIL')}
                    id="reseller.Contacts.techSupport.email"
                    name="EMAIL"
                    placeholder={t('EMAIL_PLACEHOLDER')}
                    error={errors.reseller?.Contacts?.techSupport?.email}
                    required={true}
                    component={FieldWrapper}
                    type={EFormType.EMAIL}
                    cyName="resellers-add-ctech-email-input"
                    touched={touched.reseller?.Contacts?.techSupport?.email}
                  />
                  <InputWrapper
                    mdSize={3}
                    xsSize={12}
                    text={t('PHONE')}
                    id="reseller.Contacts.techSupport.phone"
                    name="PHONE"
                    placeholder={t('PHONE_PLACEHOLDER')}
                    defaultValue={values.reseller?.Contacts?.techSupport?.phone}
                    error={errors.reseller?.Contacts?.techSupport?.phone}
                    required={true}
                    matchPortalForms={true}
                    searchPlaceholder={t('SEARCH_COUNTRY')}
                    component={PhoneFieldWrapper}
                    type={EFormType.PHONE}
                    cyName="resellers-add-ctech-phone-input"
                    touched={touched.reseller?.Contacts?.techSupport?.phone}
                  />
                  <InputWrapper
                    mdSize={3}
                    xsSize={12}
                    text={t('FAX')}
                    id="reseller.Contacts.techSupport.faxNumber"
                    name="FAX"
                    placeholder={t('FAX_PLACEHOLDER')}
                    defaultValue={
                      values.reseller?.Contacts?.techSupport?.faxNumber
                    }
                    error={errors.reseller?.Contacts?.techSupport?.faxNumber}
                    matchPortalForms={true}
                    searchPlaceholder={t('SEARCH_COUNTRY')}
                    component={PhoneFieldWrapper}
                    type={EFormType.FAX}
                    cyName="resellers-add-ctech-fax-input"
                    touched={touched.reseller?.Contacts?.techSupport?.faxNumber}
                  />

                  {(errors.reseller?.Contacts?.techSupport?.phone ||
                    errors.reseller?.Contacts?.techSupport?.email) && (
                    <div className={classes.errorCoitaniner} />
                  )}

                  <InputWrapper
                    className={classes.gridItemSpacing}
                    mdSize={4}
                    xsSize={12}
                    text={t('FIRST_NAME')}
                    id="reseller.Contacts.techSupport.firstName"
                    name="FIRST_NAME"
                    placeholder={t('FIRST_NAME_PLACEHOLDER')}
                    error={errors.reseller?.Contacts?.techSupport?.firstName}
                    type={EFormType.GENERAL}
                    required={true}
                    component={FieldWrapper}
                    cyName="resellers-add-ctech-firstname-input"
                    touched={touched.reseller?.Contacts?.techSupport?.firstName}
                  />

                  <InputWrapper
                    className={classes.gridItemSpacing}
                    mdSize={4}
                    xsSize={12}
                    text={t('SURNAME')}
                    id="reseller.Contacts.techSupport.surname"
                    type={EFormType.GENERAL}
                    name="SURNAME"
                    placeholder={t('SURNAME_PLACEHOLDER')}
                    error={errors.reseller?.Contacts?.techSupport?.surname}
                    required={true}
                    component={FieldWrapper}
                    cyName="resellers-add-ctech-lastname-input"
                    touched={touched.reseller?.Contacts?.techSupport?.surname}
                  />

                  {(errors.reseller?.Contacts?.techSupport?.firstName ||
                    errors.reseller?.Contacts?.techSupport?.surname) && (
                    <div className={classes.errorCoitaniner} />
                  )}
                </Grid>

                <AddResellerNote
                  id="reseller.Contacts.techSupport.note"
                  name="TECH_NOTE"
                  placeholder={t('NOTE_PLACEHOLDER')}
                  component={NoteWrapper}
                  error={t(noteLenghtError[0])}
                  cyName={'reseller-add-ctech-note'}
                  removeNote={() =>
                    formikRef.current.setFieldValue(
                      'reseller.Contacts.techSupport.note',
                      null
                    )
                  }
                />
                <div className={classes.SubheadingWrapperAdmin}>
                  <span className={classes.Subheading1}>
                    {t('ACCOUNT_ADMIN')}{' '}
                    <InfoOutlined className={classes.infoIcon} />
                  </span>
                </div>

                <div className={classes.checkBoxWrapperAdmin}>
                  <FormCheckbox
                    index={1}
                    id={EAccountAdminContactType.SAME_AS_TECH}
                    checked={
                      values.reseller.Contacts.accountAdmin
                        .accountAdminContactType ==
                      EAccountAdminContactType.SAME_AS_TECH
                    }
                    label={t('RESELER_CHECKBOX_3')}
                    onChange={() => {
                      setFieldError(
                        'reseller.Contacts.accountAdmin.email',
                        null
                      );
                      setFieldError(
                        'reseller.Contacts.accountAdmin.phone',
                        null
                      );
                      setFieldValue(
                        'reseller.Contacts.accountAdmin.accountAdminContactType',
                        EAccountAdminContactType.SAME_AS_TECH
                      );
                    }}
                    cyName="resellers-add-cadmin-same-radiobtn"
                  />

                  <FormCheckbox
                    index={2}
                    id={EAccountAdminContactType.NONE}
                    checked={
                      values.reseller.Contacts.accountAdmin
                        .accountAdminContactType !==
                      EAccountAdminContactType.NONE
                    }
                    label={t('RESELER_CHECKBOX_4')}
                    onChange={() => {
                      setFieldValue(
                        'reseller.Contacts.accountAdmin.accountAdminContactType',
                        EAccountAdminContactType.NONE
                      );
                    }}
                    cyName="resellers-add-cadmin-dif-radiobtn"
                  />
                </div>

                {values.reseller.Contacts.accountAdmin
                  .accountAdminContactType ==
                  EAccountAdminContactType.SAME_AS_TECH &&
                  errors.reseller?.Contacts?.accountAdmin?.email && (
                    <ErrorBar
                      value={values.reseller?.Contacts?.techSupport?.email}
                      error={t('EMAIL_ERROR_BANNER')}
                    />
                  )}

                {values.reseller.Contacts.accountAdmin
                  .accountAdminContactType ==
                  EAccountAdminContactType.SAME_AS_TECH &&
                  errors.reseller?.Contacts?.accountAdmin?.phone && (
                    <ErrorBar
                      value={values.reseller?.Contacts?.techSupport?.phone}
                      error={t('PHONE_ERROR_BANNER')}
                    />
                  )}

                {values.reseller.Contacts.accountAdmin
                  .accountAdminContactType == EAccountAdminContactType.NONE && (
                  <>
                    <Grid container alignItems="center" spacing={2}>
                      <InputWrapper
                        mdSize={6}
                        xsSize={12}
                        text={t('EMAIL')}
                        id="reseller.Contacts.accountAdmin.email"
                        name="EMAIL_ADMIN"
                        placeholder={t('EMAIL_PLACEHOLDER')}
                        error={errors?.reseller?.Contacts?.accountAdmin?.email}
                        required={!values.reseller.Contacts.accountAdmin.phone}
                        component={FieldWrapper}
                        type={EFormType.EMAIL}
                        cyName="resellers-add-cadmin-email-input"
                        touched={
                          touched.reseller?.Contacts?.accountAdmin?.email
                        }
                      />

                      <InputWrapper
                        mdSize={3}
                        xsSize={12}
                        text={t('PHONE')}
                        id="reseller.Contacts.accountAdmin.phone"
                        name="PHONE_ADMIN"
                        placeholder={t('PHONE_PLACEHOLDER')}
                        defaultValue={
                          values.reseller?.Contacts?.accountAdmin?.phone
                        }
                        error={errors?.reseller?.Contacts?.accountAdmin?.phone}
                        required={!values.reseller.Contacts.accountAdmin.email}
                        matchPortalForms={true}
                        searchPlaceholder={t('SEARCH_COUNTRY')}
                        component={PhoneFieldWrapper}
                        type={EFormType.PHONE}
                        cyName="resellers-add-cadmin-phone-input"
                        touched={
                          touched.reseller?.Contacts?.accountAdmin?.phone
                        }
                      />

                      <InputWrapper
                        mdSize={3}
                        xsSize={12}
                        text={t('FAX')}
                        id="reseller.Contacts.accountAdmin.faxNumber"
                        name="FAX_ADMING"
                        placeholder={t('FAX_PLACEHOLDER')}
                        defaultValue={
                          values.reseller?.Contacts?.accountAdmin?.faxNumber
                        }
                        error={
                          errors?.reseller?.Contacts?.accountAdmin?.faxNumber
                        }
                        matchPortalForms={true}
                        searchPlaceholder={t('SEARCH_COUNTRY')}
                        component={PhoneFieldWrapper}
                        type={EFormType.FAX}
                        cyName="resellers-add-cadmin-fax-input"
                        touched={
                          touched.reseller?.Contacts?.accountAdmin?.faxNumber
                        }
                      />

                      {(errors?.reseller?.Contacts?.accountAdmin?.phone ||
                        errors?.reseller?.Contacts?.accountAdmin?.email) && (
                        <div className={classes.errorCoitaniner} />
                      )}

                      <InputWrapper
                        className={classes.gridItemSpacing}
                        mdSize={4}
                        xsSize={12}
                        text={t('FIRST_NAME')}
                        id="reseller.Contacts.accountAdmin.firstName"
                        type={EFormType.GENERAL}
                        name="FIRST_NAME_ADMIN"
                        placeholder={t('FIRST_NAME_ADMIN_PLACEHOLDER')}
                        error={
                          errors?.reseller?.Contacts?.accountAdmin?.firstName
                        }
                        required={true}
                        component={FieldWrapper}
                        cyName="resellers-add-cadmin-firstname-input"
                        touched={
                          touched.reseller?.Contacts?.accountAdmin?.firstName
                        }
                      />

                      <InputWrapper
                        className={classes.gridItemSpacing}
                        mdSize={4}
                        xsSize={12}
                        text={t('SURNAME')}
                        id="reseller.Contacts.accountAdmin.surname"
                        type={EFormType.GENERAL}
                        name="SURNAME_ADMIN"
                        placeholder={t('SURNAME_ADMIN_PLACEHOLDER')}
                        error={
                          errors?.reseller?.Contacts?.accountAdmin?.surname
                        }
                        required={true}
                        component={FieldWrapper}
                        cyName="resellers-add-cadmin-lastname-input"
                        touched={
                          touched.reseller?.Contacts?.accountAdmin?.surname
                        }
                      />
                      {(errors?.reseller?.Contacts?.accountAdmin?.firstName ||
                        errors?.reseller?.Contacts?.accountAdmin?.surname) && (
                        <div className={classes.errorCoitaniner} />
                      )}
                    </Grid>

                    <AddResellerNote
                      id="reseller.Contacts.accountAdmin.note"
                      name="ADMIN_NOTE"
                      placeholder={t('NOTE_PLACEHOLDER')}
                      component={NoteWrapper}
                      error={t(noteLenghtError[1])}
                      cyName={'reseller-add-cadmin-note'}
                      removeNote={() =>
                        formikRef.current.setFieldValue(
                          'reseller.Contacts.accountAdmin.note',
                          null
                        )
                      }
                    />
                  </>
                )}
                <div className={classes.SubheadingWrapper}>
                  <span className={classes.Subheading1}>
                    {t('BILLING_CONTACT')}{' '}
                    <InfoOutlined className={classes.infoIcon} />
                  </span>
                </div>

                <Grid container alignItems="center" spacing={2}>
                  <InputWrapper
                    mdSize={6}
                    xsSize={6}
                    text={t('EMAIL')}
                    id="reseller.Contacts.billingContact.email"
                    name="EMAIL_BILLING"
                    placeholder={t('EMAIL_PLACEHOLDER')}
                    error={errors.reseller?.Contacts?.billingContact?.email}
                    required={true}
                    component={FieldWrapper}
                    type={EFormType.EMAIL}
                    cyName="resellers-add-cbilling-email-input"
                    touched={touched.reseller?.Contacts?.billingContact?.email}
                  />

                  <InputWrapper
                    mdSize={3}
                    xsSize={12}
                    text={t('PHONE')}
                    id="reseller.Contacts.billingContact.phone"
                    name="PHONE_BILLING"
                    placeholder={t('PHONE_PLACEHOLDER')}
                    defaultValue={
                      values.reseller?.Contacts?.billingContact?.phone
                    }
                    error={errors.reseller?.Contacts?.billingContact?.phone}
                    required={true}
                    matchPortalForms={true}
                    searchPlaceholder={t('SEARCH_COUNTRY')}
                    component={PhoneFieldWrapper}
                    type={EFormType.PHONE}
                    cyName="resellers-add-cbilling-phone-input"
                    touched={touched.reseller?.Contacts?.billingContact?.phone}
                  />

                  <InputWrapper
                    mdSize={3}
                    xsSize={12}
                    text={t('FAX')}
                    id="reseller.Contacts.billingContact.faxNumber"
                    name="FAX_BILLING"
                    placeholder={t('FAX_PLACEHOLDER')}
                    defaultValue={
                      values.reseller?.Contacts?.billingContact?.faxNumber
                    }
                    error={errors.reseller?.Contacts?.billingContact?.faxNumber}
                    matchPortalForms={true}
                    searchPlaceholder={t('SEARCH_COUNTRY')}
                    component={PhoneFieldWrapper}
                    type={EFormType.FAX}
                    cyName="resellers-add-cbilling-fax-input"
                    touched={
                      touched.reseller?.Contacts?.billingContact?.faxNumber
                    }
                  />

                  {(errors.reseller?.Contacts?.billingContact?.email ||
                    errors.reseller?.Contacts?.billingContact?.phone) && (
                    <div className={classes.errorCoitaniner} />
                  )}

                  <InputWrapper
                    className={classes.gridItemSpacing}
                    mdSize={4}
                    xsSize={12}
                    text={t('FIRST_NAME')}
                    id="reseller.Contacts.billingContact.firstName"
                    type={EFormType.GENERAL}
                    name="FIRST_NAME_BILLING"
                    placeholder={t('FIRST_NAME_BILLING_PLACEHOLDER')}
                    error={errors.reseller?.Contacts?.billingContact?.firstName}
                    required={true}
                    component={FieldWrapper}
                    cyName="resellers-add-cbilling-firstname-input"
                    touched={
                      touched.reseller?.Contacts?.billingContact?.firstName
                    }
                  />

                  <InputWrapper
                    className={classes.gridItemSpacing}
                    mdSize={4}
                    xsSize={12}
                    text={t('SURNAME')}
                    id="reseller.Contacts.billingContact.surname"
                    type={EFormType.GENERAL}
                    name="SURNAME_BILLING"
                    placeholder={t('SURNAME_BILLING_PLACEHOLDER')}
                    error={errors.reseller?.Contacts?.billingContact?.surname}
                    required={true}
                    component={FieldWrapper}
                    cyName="resellers-add-cbilling-lastname-input"
                    touched={
                      touched.reseller?.Contacts?.billingContact?.surname
                    }
                  />

                  {(errors.reseller?.Contacts?.billingContact?.firstName ||
                    errors.reseller?.Contacts?.billingContact?.surname) && (
                    <div className={classes.errorCoitaniner} />
                  )}
                </Grid>

                <AddResellerNote
                  id="reseller.Contacts.billingContact.note"
                  name="ADMIN_NOTE"
                  placeholder={t('NOTE_PLACEHOLDER')}
                  component={NoteWrapper}
                  error={t(noteLenghtError[2])}
                  cyName={'reseller-add-cbilling-note'}
                  removeNote={() =>
                    formikRef.current.setFieldValue(
                      'reseller.Contacts.billingContact.note',
                      null
                    )
                  }
                />
              </Card>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};
